const colors = ['purple', 'blue', 'orange', 'red']


var typed = new Typed('.search-field span', {
  typeSpeed: 70,
  backSpeed: 30,
  backDelay: 2000,
  strings: [
    'What makes me more productive: coffee or nap?',
    'Do I have a better sleep when I don’t eat sweets?',
    'What contributed to an increase in my weight recently?',
    'What makes my mood better?'
  ],
  loop: true,
  preStringTyped: (arrayPos, self) => {
    switchColor()
  },
});

function switchColor() {
  let search = $('.search-field').eq(0)
  let color = search.attr('id')
  let index;

  if (color !== 'start')
    index = colors.findIndex(i => i === color)
  else
    index = -1

  search.attr('id', colors[index === colors.length - 1 ? 0 : index + 1])
}


$('#form-submit').click(function () {
  let success = document.getElementById('submit-success')
  axios.put("https://api.sendgrid.com/v3/marketing/contacts", {
    "list_ids": [
      "223ddfcb-0299-4ad3-9ed4-fbf5da919eba"
    ],
    "contacts": [
      {
        "email": document.getElementById('email').value
      }
    ]
  }, {
    headers: {
      'Content-Type': 'application/json',
      "Authorization": "Bearer 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"
    }
  })
    .then(res => {
      success.classList.add('done')
      console.log(res)
    })
    .catch(e => {
      // Failed to add email
      console.log(e)
    })
})

$('.beta-button').click(function () {
  console.log(123)
  let form = $('.form-wrapper')
  var offset = form.offset();
  offset.left -= 20;
  offset.top -= 20;
  $('html, body').animate({
    scrollTop: offset.top,
    scrollLeft: offset.left
  });
})

// window.addEventListener('scroll', function (e) {
//   let features = [$('#feature-1'), $('#feature-2'), $('#feature-3')]
//
//   features.forEach(function (i) {
//     if (window.pageYOffset >= (i.offset().top - window.innerHeight + 100) && !i[0].classList.contains('show')) {
//       console.log(123333)
//       i[0].classList.add('show')
//     }
//   })
// })
